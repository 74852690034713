import React, { FC } from 'react';
import { PopupContentConfigColumnOtherProps } from 'src/components/listScreen/organisms/popupContentConfig/popupContentConfigOther/types';

import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';

export const PopupContentConfigColumnOther: FC<PopupContentConfigColumnOtherProps> = ({
    updateIsCondensedView,
    globalPopupState
}) => {
    return (
        <div>
            <UiCheckbox
                label={'Widok skondensowany'}
                checked={globalPopupState.config.isCondensedView}
                onChange={updateIsCondensedView}
            />
        </div>
    );
};

import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { transformationsSlice, searchFieldsSlice, tableDataSlice } from '../../rootReducer';

export {
    setData,
    ubdateData,
    ubdateTreeNode
} from 'src/store/src/listScreen/treeData/treeDataSlice';

export {
    setRowsData,
    setColumnsData,
    setColumnsDatasums,
    setFooterActionsExist,
    setTargetScreenCode,
    setTotalIds,
    setTotalIdsIsActual,
    setColumnsConfiguration,
    setIsCondensedView,
    changeColumnOrder,
    changeColumnConfiguration
} from './tableData/tableDataSlice';
export {
    setActualPage,
    setObjectsPerPage,
    setSorting,
    setPaging,
    setFilters,
    setColumnFilters
} from './transformations/transformationsSlice';
export { setSearchFields } from './searchFields/searchFieldsSlice';
export { setStatusTableFromApi, setStatusTableObjects } from './statusData/statusDataSlice';

export { updateObjectsData, fetchAndLoadInitialListScreenData } from './sharedActions';

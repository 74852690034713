import React, { useReducer, createContext, Reducer } from 'react';

export type ConfigPropertyObj = {
    [code: string]: {
        isPinned: boolean;
        isVisible: boolean;
    };
};

export type ConfigPropertyArrayItem = {
    id: string;
    fieldType: string;
    code: string;
    isPinned: boolean;
    isVisible: boolean;
    name: string;
    statusIconPinned?: 'isDisabled' | 'isActive' | 'isPinned';
};

export type ConfigState = {
    limitIsAchieved: boolean;
    isCondensedView: boolean;
    propertyCol: ConfigPropertyArrayItem[];
    propertyColObj: ConfigPropertyObj;
};

export type GlobalPopupState = {
    config: ConfigState;
};

export enum ActionKind {
    SET_CONFIG = 'SET_CONFIG',
    SET_IS_CONDENSED_VIEW = 'SET_IS_CONDENSED_VIEW'
}

export interface Action {
    type: keyof typeof ActionKind;
    payload: GlobalPopupState;
}

export const reducerGlobalPopupState: Reducer<GlobalPopupState, Action> = (state, action) => {
    switch (action.type) {
        case ActionKind.SET_CONFIG:
            return {
                ...state,
                config: action.payload.config
            };
        case ActionKind.SET_IS_CONDENSED_VIEW:
            return {
                ...state,
                config: {
                    ...state.config,
                    isCondensedView: !state.config.isCondensedView
                }
            };
        default:
            return {
                ...state
            };
    }
};

export const initialState = {
    config: {
        limitIsAchieved: false,
        propertyCol: [],
        propertyColObj: {},
        isCondensedView: false
    }
};

const GlobalPopup = createContext<{
    globalPopupState: GlobalPopupState;
    dispatchGlobalPopupContext: React.Dispatch<Action>;
}>({
    globalPopupState: initialState,
    dispatchGlobalPopupContext: () => null
});

const GlobalPopupProvider: React.FC = ({ children }) => {
    const [globalPopupState, dispatchGlobalPopupContext] = useReducer(
        reducerGlobalPopupState,
        initialState
    );

    return (
        <GlobalPopup.Provider value={{ globalPopupState, dispatchGlobalPopupContext }}>
            {children}
        </GlobalPopup.Provider>
    );
};

export { GlobalPopupProvider, GlobalPopup };

import React, { FC } from 'react';

import PopupTemplateFirst from 'src/components/shared/organisms/popupTemplateFirst/PopupTemplateFirst';
import styles from './styles.module.css';
import { usePopupContentConfig } from 'src/components/listScreen/organisms/popupContentConfig/usePopupContentConfig';
import { PopupContentConfigColumnSection } from 'src/components/listScreen/organisms/popupContentConfig/popupContentConfigColumnSection/PopupContentConfigColumnSection';
import { PopupContentConfigLine } from './popupContentConfigLine/PopupContentConfigLine';
import { PopupContentConfigColumnOther } from 'src/components/listScreen/organisms/popupContentConfig/popupContentConfigOther/PopupContentConfigOther';

const PopupContentConfig: FC = () => {
    const { setAll, ubdatePinnedItem, ubdateVisibleItem, updateIsCondensedView, globalPopupState } =
        usePopupContentConfig();

    return (
        <PopupTemplateFirst onClick={setAll} buttonTxt={'Zapisz'} title={'Konfiguracja'}>
            <div className={styles.wrapper}>
                <PopupContentConfigColumnSection
                    ubdatePinnedItem={ubdatePinnedItem}
                    ubdateVisibleItem={ubdateVisibleItem}
                    globalPopupState={globalPopupState}
                />
                <PopupContentConfigLine />
                <PopupContentConfigColumnOther
                    updateIsCondensedView={updateIsCondensedView}
                    globalPopupState={globalPopupState}
                />
            </div>
        </PopupTemplateFirst>
    );
};

export default PopupContentConfig;

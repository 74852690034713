import React, { FC, useContext, useEffect } from 'react';

import { useTypedSelector, useAppDispatch } from 'src/store';
import { TableStructureManager } from 'src/store/src/listScreen/tableData/TableStructureManager';
import { GlobalPopup, ConfigPropertyArrayItem } from 'src/context/globalPopup/store';
import { useSearchParam } from 'src/hooks';
import { changeColumnConfiguration } from 'src/store/src/listScreen';
import { closePopup } from 'src/store/src/popup';

export const usePopupContentConfig = () => {
    const screen = useSearchParam('obiekt');
    const objectDataState = useTypedSelector((state) => state.listScreenTableData);
    const dispatch = useAppDispatch();
    const { dispatchGlobalPopupContext, globalPopupState } = useContext(GlobalPopup);

    const setAll = () => {
        if (!screen) {
            console.error('Brak parametru "obiekt"');
            return;
        }
        const apiConfig = TableStructureManager.generateDataConfigForApi(
            globalPopupState.config.propertyCol
        );
        dispatch(
            changeColumnConfiguration(screen, {
                apiConfig: apiConfig,
                storeConfig: globalPopupState.config.propertyColObj,
                isCondensedView: globalPopupState.config.isCondensedView
            })
        );

        dispatch(closePopup());
    };

    const ubdatePinnedItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdatePinnedColItem(
            globalPopupState.config,
            item
        );
        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...globalPopupState.config,
                    ...configPinnedCol
                }
            }
        });
    };

    const ubdateVisibleItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdateVisilbleColItem(
            globalPopupState.config,
            item
        );
        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...globalPopupState.config,
                    ...configPinnedCol
                }
            }
        });
    };

    const updateIsCondensedView = () => {
        dispatchGlobalPopupContext({
            type: 'SET_IS_CONDENSED_VIEW',
            payload: {
                config: globalPopupState.config
            }
        });
    };

    useEffect(() => {
        const configPropertyCol = new TableStructureManager({
            columnDataEntries: objectDataState.columnData
        }).setStatePopupPropertyCol();

        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: {
                    ...configPropertyCol,
                    isCondensedView: objectDataState.isCondensedView
                }
            }
        });
    }, [dispatchGlobalPopupContext, objectDataState]);

    return {
        ubdateVisibleItem,
        ubdatePinnedItem,
        updateIsCondensedView,
        setAll,
        globalPopupState
    };
};
